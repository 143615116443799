<template>
  <div class="home">
    <Info v-if="selectedItem" :item="selectedItem" :isVisible="isInfoModalVisible"
          @update:isVisible="isInfoModalVisible = $event"/>
    <mast-head></mast-head>
    <b-container>
      <b-row>
        <b-col>
          
          <div>
            <h2 class="color2 mb-5">Welcome to this online learning experience.</h2>
            <div class="text-left">
              The Domestic Abuse Act 2021 requires organisations of all sizes and types to help ensure victims receive effective and safe responses, through increasing knowledge and awareness of the signs of domestic abuse and how to respond.
              <br/>
              <br/>Modern slavery is happening in the UK today, but the crime can be hard to spot and go unreported. The training below gives you up to date information on the signs and what you can do.
              <br/>
              <br/>These online modules provide a wide range of advice and guidance for all types of agencies to help you make a difference to those affected by domestic abuse and modern slavery.
            </div>
          </div>
          <div v-if="!GET_LOGGED_IN_STATUS" class="notice mx-auto mt-5 font-weight-bold">
            <div>Please note we have updated these modules.</div>
            <div>
              You are required to re-register to access the site and to use the newly updated modules.
            </div>
          </div>
        </b-col>

      </b-row>
      <b-row class="my-5" v-if="!GET_LOGGED_IN_STATUS">
        <b-col md="1"></b-col>
        <b-col>
          <b-btn md="4" class="background-color2 mb-1 p-3 font-weight-bold" @click="$router.push('login')">Login</b-btn>
        </b-col>
        <b-col>
          <b-btn md="4" class="background-color2 mb-1 p-3 font-weight-bold" @click="$router.push('register')">Register
          </b-btn>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
      <b-row class="my-5" v-else>
        <b-col>
          <div class="module-buttons mb-5 ">
            <div class="row d-flex align-items-stretch">
              <div class="col-md-2"></div>
              <div class="col-md-4  mb-4 mb-md-2   d-flex flex-column"
                   v-for="(item, index) in moduleModernSlavery"
                   :key="item.id">
                <button
                    @click.stop="gotoCourse(item.dir)"
                    class="w-100 background-transparent border-0 flex-grow-1">
                        <span class="content-box-grid__block">
              <span class="content-box-grid__block-image"
                    :style="{ backgroundImage: `url(${getImageUrl(item.id)})` }"></span>
              <span class="content-box-grid__block-content label text-left ">
                <span class="label-title">{{ item.title }}
                </span>
                <span class="label-intro mt-2">
                  {{ item.intro }}
                </span>
              </span>
            </span>
                </button>
                <button
                    @click="openInfoModal(item)"
                    class="w-100 border-0 font-weight-bold background-color3 info mt-2 py-2">Info
                </button>
              </div>
            </div>
          </div>
        </b-col>

      </b-row>

    </b-container>

  </div>
</template>

<script>
import {languageObject} from "@/json/lang/lang";
import Login from "@/views/Login";
import MastHead from "@/components/MastHead";
import {mapState, mapGetters} from "vuex";
import Info from "@/views/Info.vue";

export default {
  name: "home",
  components: {
    Info,
    Login,
    MastHead
  },
  data() {
    return {
      selectedItem: null,
      isInfoModalVisible: false
    };
  },
  computed: {
    ...mapState([
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    ...mapGetters([
      'GET_LOGGED_IN_STATUS'
    ]),
    moduleModernSlavery() {
      let modules = [];
      if (languageObject['en'].module) {
        modules = languageObject['en'].module;
      }
      return modules.filter(module => module.type === "homepage");
    }
  },
  methods: {
    openInfoModal(item) {
      this.selectedItem = item; // Set the selected item
      this.isInfoModalVisible = true; // Show the Info modal
    },
    gotoCourse(dir) {

      if (dir === "modules") { //Modules link
        //window.location.href = '/modules';
        this.$router.push('/modules');
      } else {
        //Redirect to the actual course
        window.location.href = '/practitioners/' + dir + '/index.html';
      }

    },
    getImageUrl(id) {
      return require(`@/assets/img/module-menu/${id}.jpg`);
    },

  }
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";

.notice {
  border: 2px solid $color2; // Purple
  background-color: $color3; // Lime
  color: $color0; // Dark Gray for text
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;

  h2 {
    margin-top: 0;
    color: $color2; // Purple
    font-size: 1.5rem;
  }

  p {
    margin: 0.5rem 0;
  }

  a {
    display: inline-block;
    text-decoration: none;
    background-color: $color6; // Crimson
    color: $color1; // White
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
      background-color: $color7; // Dark Red
    }
  }
}

</style>
